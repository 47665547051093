import { parseEther } from 'ethers'
import moment from 'moment'

import api from './api'

const TokenSaleService = {
  createTokenSale: async (
    formState,
    musharakahAllowed = false,
    isRefinancing,
    thumbnail = null,
    images = [],
    documents = [],
  ) => {
    const formData = new FormData()

    formData.append('title', formState.title)
    formData.append('description', formState.description)
    formData.append('address', formState.address)
    formData.append('city', formState.city)
    formData.append('country', formState.country)
    formData.append('token_total_supply', parseEther(formState.tokenTotalSupply))
    formData.append('token_price', parseEther(formState.tokenPrice))
    if (formState.saleMinBuy) {
      formData.append('sale_min_buy', parseEther(formState.saleMinBuy))
    }
    if (formState.saleMaxBuy) {
      formData.append('sale_max_buy', parseEther(formState.saleMaxBuy))
    }
    formData.append('beneficiary_address', formState.beneficiaryAddress)
    if (formState.saleStartAt) {
      formData.append('sale_start_at', moment(formState.saleStartAt).unix())
    }
    formData.append('sale_end_at', moment(formState.saleEndAt).unix())
    formData.append('projected_annual_return', formState.projectedAnnualReturn)
    formData.append('projected_rental_yield', formState.projectedRentalYield)
    formData.append('rental_yield', formState.rentalYield)
    formData.append('rent_amount', parseEther(formState.rentAmount))
    formData.append('musharakah_allowed', musharakahAllowed)

    // Refiance fields
    formData.append('is_refinance_request', isRefinancing)
    formData.append('refinance_equity_owned', formState.houseEquityOwned)

    if (thumbnail) {
      formData.append('thumbnail', thumbnail)
    }
    images.forEach(image => {
      formData.append('images', image)
    })
    documents.forEach(document => {
      formData.append('documents', document)
    })
    try {
      const response = await api.post('/token-sales', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return response.data.data
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  getTokenSales: async () => {
    const response = await api.get('/token-sales')
    return response.data.data.items
  },

  getTokenSale: async (id) => {
    const response = await api.get(`/token-sales/${id}`)
    return response.data.data
  },

  getTokenSaleRequests: async () => {
    const response = await api.get('/token-sales/requests')
    return response.data.data.items
  },

  getTokenSaleRequest: async (id) => {
    const response = await api.get(`/token-sales/requests/${id}`)
    return response.data.data
  },

  updateTokenSale: async (id, formState) => {
    const formData = new FormData()
    formData.append('token_total_supply', parseEther(formState.tokenTotalSupply))
    formData.append('house_sale_price', parseEther(formState.houseSalePrice))
    formData.append('rent_amount', parseEther(formState.rentAmount))
    formData.append('annual_operating_expenses', formState.annualOperatingExpenses)
    // Debuggin code
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`)
    }
    try {
      const response = await api.post(`/token-sales/requests/${id}/update`, formData)
      return response.data.data
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  approveTokenSaleRequest: async (id) => {
    const response = await api.put(`/token-sales/requests/${id}/approve`)
    return response.data.data
  },

  fetchInvestors: async id => {
    const { data } = await api.get(`/token-sales/${id}/investors`)
    return data.data
  },

  fetchTransactions: async id => {
    const { data } = await api.get(`/token-sales/${id}/transactions`)
    return data.data
  },

  updatePrice: async (id, price) => {
    await api.put(`token-sales/${id}/token-price`, { price })
  },
}

export default TokenSaleService
