import styles from './styles.module.scss'
import { NavLink } from 'react-router-dom'
import SideBarIcon from '../../assets/images/sidebar-icons/icon-1.svg'
import NewIcon from '../../assets/images/sidebar-icons/new-icon.svg'

const SidebarMenu = () => {
  return (
    <div className={styles.wrapper}>
      <h1>Admin Panel</h1>
      <nav className={styles.nav}>
        <NavLink to="/new-token-sale" className={styles.createNewBnt}>
          <span className={styles.iconWrapper}>
            <img src={NewIcon} alt="" />
          </span>
          <span>New Token Sale</span>
        </NavLink>
        <NavLink
          to="/admins"
          className={({ isActive, isPending }) =>
            isPending ? styles['pending'] : isActive ? styles['active'] : ''
          }
        >
          <span className={styles.iconWrapper}>
            <img src={SideBarIcon} alt="" />
          </span>
          <span>Admins</span>
        </NavLink>
        <hr />
        <NavLink
          to="/dashboard"
          className={({ isActive, isPending }) =>
            isPending ? styles['pending'] : isActive ? styles['active'] : ''
          }
        >
          <span className={styles.iconWrapper}>
            <img src={SideBarIcon} alt="" />
          </span>
          <span>Dashboard</span>
        </NavLink>
        <NavLink
          to="/musharakah"
          className={({ isActive, isPending }) =>
            isPending ? styles['pending'] : isActive ? styles['active'] : ''
          }
        >
          <span className={styles.iconWrapper}>
            <img src={SideBarIcon} alt="" />
          </span>
          <span>Musharakah</span>
        </NavLink>
        <NavLink
          to="/tokensales"
          className={({ isActive, isPending }) =>
            isPending ? styles['pending'] : isActive ? styles['active'] : ''
          }
        >
          <span className={styles.iconWrapper}>
            <img src={SideBarIcon} alt="" />
          </span>
          <span>Open Tokensales</span>
        </NavLink>
        <NavLink
          to="/completed-tokensales"
          className={({ isActive, isPending }) =>
            isPending ? styles['pending'] : isActive ? styles['active'] : ''
          }
        >
          <span className={styles.iconWrapper}>
            <img src={SideBarIcon} alt="" />
          </span>
          <span>Completed Tokensales</span>
        </NavLink>
        <NavLink
          to="/tokensale-requests"
          className={({ isActive, isPending }) =>
            isPending ? styles['pending'] : isActive ? styles['active'] : ''
          }
        >
          <span className={styles.iconWrapper}>
            <img src={SideBarIcon} alt="" />
          </span>
          <span>Tokensales Requests</span>
        </NavLink>
        <NavLink
          to="/transactions"
          className={({ isActive, isPending }) =>
            isPending ? styles['pending'] : isActive ? styles['active'] : ''
          }
        >
          <span className={styles.iconWrapper}>
            <img src={SideBarIcon} alt="" />
          </span>
          <span>Transactions</span>
        </NavLink>
        <NavLink
          to="/users"
          className={({ isActive, isPending }) =>
            isPending ? styles['pending'] : isActive ? styles['active'] : ''
          }
        >
          <span className={styles.iconWrapper}>
            <img src={SideBarIcon} alt="" />
          </span>
          <span>Users</span>
        </NavLink>
        <hr />
        <NavLink
          to="http://164.92.86.68/"
          className={({ isActive, isPending }) =>
            isPending ? styles['pending'] : isActive ? styles['active'] : ''
          }
        >
          <span className={styles.iconWrapper}>
            <img src={SideBarIcon} alt="" />
          </span>
          <span>Blockchain Stats</span>
        </NavLink>
        <NavLink
          to="/stablecoin-stats"
          className={({ isActive, isPending }) =>
            isPending ? styles['pending'] : isActive ? styles['active'] : ''
          }
        >
          <span className={styles.iconWrapper}>
            <img src={SideBarIcon} alt="" />
          </span>
          <span>Stablecoin Stats</span>
        </NavLink>
      </nav>
    </div>
  )
}

export default SidebarMenu
